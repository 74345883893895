// Require Twitter Bootstrap scripts
require('bootstrap');
require('waypoints/lib/jquery.waypoints.js');
import Swiper from 'swiper/dist/js/swiper.js';

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Starter = {
		// All pages
		'common': {
			init: function() {

				var mySwiper = new Swiper('.swiper-carousel', {
					direction: 'horizontal',
					loop: true,
					autoplay: {
						delay: carouselSpeed,
					},
					navigation: {
						nextEl: '.next',
						prevEl: '.prev',
					},
					keyboard: {
						enabled: true,
						onlyInViewport: false,
					},
					on: {
						slideChange: function(){
							var index = this.realIndex + 1;
							$('.carousel__counter span').text(index);
						}
					}
				});

				var bookSwiper = new Swiper('.swiper-book', {
					direction: 'horizontal',
					autoplay: {
						delay: carouselSpeed,
					},
					scrollbar: {
						el: '.swiper-scrollbar',
						draggable: true,
						hide: false
					},
					keyboard: {
						enabled: true
					},
					navigation: {
						nextEl: '.next',
						prevEl: '.prev',
					},
					mousewheel: {
						invert: true,
					},
					on: {
						slideChange: function () {
							var index = this.realIndex + 1;
							$('.carousel__counter span').text(index);
						}
					}
				});

				var FullscreenSwiper = new Swiper('.fullscreenswiper-container', {
					direction: 'horizontal',
					init: false,
					loop: true,
					preloadImages: false,
					lazy: {
						loadPrevNext: true,
					},
					keyboard: {
						enabled: true
					},
					navigation: {
						nextEl: '.next',
						prevEl: '.prev',
					},
					on: {
						slideChange: function () {
							var index = this.realIndex + 1;
							var $el = $(this.slides[index]);
							var caption = $el.data('caption');
							var $carouseldata = $('.carouseldata');
							$carouseldata.find('.carouseldata__counter span').text(index);
							$carouseldata.find('.carouseldata__caption').text(caption);
						}
					}
				});

				$('.swipelink').on('click', function(){
					$('body').addClass('fullscreen-carousel--visible');
					var index = $(this).data('index') + 1;

					FullscreenSwiper.init();
					FullscreenSwiper.update();
					FullscreenSwiper.lazy.loadInSlide(index);
					FullscreenSwiper.slideTo(index, 0);
				});
				$('.fullscreen-exit').on('click', function(){
					$('body').removeClass('fullscreen-carousel--visible');
				});

				function waypoints() {
					var $sections = $('.right_column__group');
					var $texts = $('.leftcolumn__group');
					var currentVisibileID = 0;

					function waypointhandler($el){
						var currentVisibileID = $el.data('slide');

						$texts.removeClass('active-item').filter(function (index, elm) {
							var slide = $(elm).data('slide');
							return slide == currentVisibileID;
						}).addClass('active-item');
					}

					var waypointsDown =
					$sections.waypoint({
						handler: function (direction) {
							if (direction == 'down'){
								var $el = $(this.element);
								waypointhandler($el);
							}
						},
						offset: '25%'
					})

					var waypointsUp =
					$sections.waypoint({
						handler: function (direction) {
							if (direction == 'up') {
								var $el = $(this.element);
								waypointhandler($el);
							}
						},
						offset: '0'
					})
				}

				$(window).on('scroll', waypoints());

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Starter;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

  	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
